import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../../components/Layouts/layout'
import SEO from '../../../components/seo'
import SubPageNav from '../../../components/SubPageNav/SubPageNav'
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav'
import Lightbulb from '../../../svgs/lightbulb.svg'
import ToolTip from '../../../components/ToolTip/ToolTip'

const HombuyersJourneyStep3Page = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query {
      homebuyerImg: file(relativePath: { eq: "napier_homeBG.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout
      view='buyer'
      breadcrumb={{
        text: <FormattedMessage id='buyerNav.dashboard' />,
        path: '/new-home-buyer/dashboard/'
      }}
      header={<FormattedMessage id='buyerNav.steps' />}
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'buyerNav.step3' })}
      />
      <Container>
        <Row>
          <Col>
            <SubPageNav
              links={[
                {
                  text: <FormattedMessage id='buyerNav.step1' />,
                  path: '/new-home-buyer/steps-in-home-buyers-journey/step-1/'
                },
                {
                  text: <FormattedMessage id='buyerNav.step2' />,
                  path: '/new-home-buyer/steps-in-home-buyers-journey/step-2/'
                },
                {
                  text: <FormattedMessage id='buyerNav.step3' />,
                  path: '/new-home-buyer/steps-in-home-buyers-journey/step-3/',
                  active: true
                },
                {
                  text: <FormattedMessage id='buyerNav.step4' />,
                  path: '/new-home-buyer/steps-in-home-buyers-journey/step-4/'
                },
                {
                  text: <FormattedMessage id='buyerNav.step5' />,
                  path: '/new-home-buyer/steps-in-home-buyers-journey/step-5/'
                },
                {
                  text: <FormattedMessage id='buyerNav.step6' />,
                  path: '/new-home-buyer/steps-in-home-buyers-journey/step-6/'
                }
              ]}
            />
          </Col>
        </Row>

        <Row className='pageRow'>
          <Col>
            <h2>
              <FormattedMessage id='nav.step' /> 3{' '}
              <span className='thin'>
                {' '}
                <FormattedMessage id='buyerNav.step3' />{' '}
              </span>
            </h2>
            <p>
              <FormattedMessage id='homebuyersStep3.contentA' />{' '}
              <ToolTip
                link='/glossary/#agreePS'
                internal
                definition={<FormattedMessage id='glossaryTerms.agreeDef' />}
              >
                <FormattedMessage id='glossaryTerms.agreeTerm' />
              </ToolTip>{' '}
              <FormattedMessage id='homebuyersStep3.contentB' />
            </p>

            <h3 className='sm'>
              <FormattedMessage id='homebuyersStep3.headerA' />
            </h3>
            <p>
              <FormattedMessage id='homebuyersStep3.contentC' />
            </p>

            <h3 className='sm'>
              <FormattedMessage id='homebuyersStep3.headerB' />
            </h3>
            <p>
              <FormattedMessage id='homebuyersStep3.contentD' />
            </p>
          </Col>
          <Col xl={3} md={12}>
            <Img fluid={data.homebuyerImg.childImageSharp.fluid} alt='' />
          </Col>
        </Row>
      </Container>

      <section className='fullWidthRow'>
        <Container>
          <Row align='center' style={{ marginBottom: `1rem` }}>
            <Col md={1}>
              <Lightbulb />
            </Col>

            <Col md={11}>
              <h2 style={{ marginBottom: `0` }}>
                <span className='thin'>
                  {' '}
                  <FormattedMessage id='nav.tipsFor' />{' '}
                </span>
                <br />
                <FormattedMessage id='homebuyersStep3.headerC' />
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p>
                <FormattedMessage id='homebuyersStep3.contentE' />{' '}
                <ToolTip
                  link='/glossary/#agreePS'
                  internal
                  definition={<FormattedMessage id='glossaryTerms.agreeDef' />}
                >
                  <FormattedMessage id='glossaryTerms.agreeTerm' />
                </ToolTip>
                :
              </p>
              <ol className='largeNums'>
                <li>
                  <FormattedMessage id='homebuyersStep3.list01A' />
                </li>
                <li>
                  <div>
                    <FormattedMessage id='homebuyersStep3.list01B' />
                    <ul>
                      <li>
                        <FormattedMessage id='homebuyersStep3.list01C' />
                      </li>
                      <li>
                        <FormattedMessage id='homebuyersStep3.list01Di' />{' '}
                        <a
                          href='https://www.tarion.com/homeowners/pre-possession-coverage'
                          aria-label={intl.formatMessage({
                            id: 'homebuyersStep3.list01Dii'
                          })}
                        >
                          <FormattedMessage id='homebuyersStep3.list01Dii' />
                        </a>{' '}
                        <FormattedMessage id='homebuyersStep3.list01Diii' />
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <FormattedMessage id='homebuyersStep3.list01E' />
                </li>
                <li>
                  <div>
                    <FormattedMessage id='homebuyersStep3.list01F' />
                    <ul>
                      <li>
                        <FormattedMessage id='homebuyersStep3.list01G' />
                      </li>
                      <li>
                        <FormattedMessage id='homebuyersStep3.list01H' />
                      </li>
                      <li>
                        <FormattedMessage id='homebuyersStep3.list01I' />
                      </li>
                      <li>
                        <FormattedMessage id='homebuyersStep3.list01J' />
                      </li>
                    </ul>
                  </div>
                </li>
              </ol>
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col md={12}>
            <SubPageBottomNav
              prevLink={{
                text: <FormattedMessage id='buyerNav.step2' />,
                path: '/new-home-buyer/steps-in-home-buyers-journey/step-2/'
              }}
              nextLink={{
                text: <FormattedMessage id='buyerNav.step4' />,
                path: '/new-home-buyer/steps-in-home-buyers-journey/step-4/'
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default HombuyersJourneyStep3Page
